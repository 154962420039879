import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const IS_ACTIVE_CHECKOUT_M1_KEY = 'IS_ACTIVE_CHECKOUT_M1';
export const IS_ACTIVE_CHECKOUT_M2_KEY = 'IS_ACTIVE_CHECKOUT_M2';

export const is_active_checkout_m1_atom = atomWithStorage(IS_ACTIVE_CHECKOUT_M1_KEY, false);
export const is_active_checkout_m2_atom = atomWithStorage(IS_ACTIVE_CHECKOUT_M2_KEY, true);

export const m1_checkout_route_prefix_atom = atom((get) => {
  const is_active_checkout_m1 = get(is_active_checkout_m1_atom);
  if (is_active_checkout_m1) {
    return '/checkout';
  }
  return '';
});

export const m2_checkout_route_prefix_atom = atom((get) => {
  const is_active_checkout_m2 = get(is_active_checkout_m2_atom);
  if (is_active_checkout_m2) {
    return '/checkout';
  }
  return '';
});
